import { Component, HostListener } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'jenu-service',
  templateUrl: './jenu-service.component.html',
  styleUrls: ['./jenu-service.component.scss']
})
export class JenuServiceComponent {
  displayYear: number = new Date().getFullYear();
  navBar= true;
  showNavBar(){
    this.navBar  = !this.navBar;
  }

  @HostListener('window:resize', ['$event'])
onResize() {
  if(window.innerWidth  >= 992){
    this.navBar = true;
  };
}
}
