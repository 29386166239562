<div class="hero_area">
    <!-- header section strats -->
    <header class="header_section">
      <div class="header_top">
        <div class="container-fluid ">
          <div class="contact_nav">
            <a routerLink="">
              <i class="fa fa-phone" aria-hidden="true"></i>
              <span>
                Call : +91 9008160307
              </span>
            </a>
            <a routerLink="">
              <i class="fa fa-envelope" aria-hidden="true"></i>
              <span>
                Email : info@jenudigitech.com
              </span>
            </a>
            <a href="https://maps.app.goo.gl/jTQ4DTdp2eTzmTCU8">
              <i class="fa fa-map-marker" aria-hidden="true"></i>
              <span>
                Location
              </span>
            </a>
          </div>
        </div>
      </div>
      <div class="header_bottom">
        <div class="container-fluid">
          <nav class="navbar navbar-expand-lg custom_nav-container ">
            <a class="navbar-brand" routerLink="/our-solutions">
              <span>
                <span class="jenu-color">Jenu </span><span class="digitech-color">Digitech Labs Private Limited</span>
              </span>
            </a>
  
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"  (click)="showNavBar()" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span class=""> </span>
            </button>
  
            <div class="navbar-collapse" id="navbarSupportedContent"   *ngIf="navBar">
              <ul class="navbar-nav  ">
                <li class="nav-item ">
                  <a class="nav-link" routerLink="/our-solutions">Home <span class="sr-only">(current)</span></a>
                </li>
                <li class="nav-item ">
                  <a class="nav-link" routerLink="/services">Services</a>
                </li>
                <li class="nav-item ">
                  <a class="nav-link" routerLink="/about-us"> About Us</a>
                </li>
                <li class="nav-item active">
                  <a class="nav-link" routerLink="/contact-us">Contact Us</a>
                </li>
                <!-- <li class="nav-item">
                  <a class="nav-link" routerLink="#"> <i class="fa fa-user" aria-hidden="true"></i> Login</a>
                </li>
                <form class="form-inline">
                  <button class="btn  my-2 my-sm-0 nav_search-btn" type="submit">
                    <i class="fa fa-search" aria-hidden="true"></i>
                  </button>
                </form> -->
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </header>
  
    <section class="contact_section">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-4 col-md-5 offset-md-1">
              <div class="heading_container">
                <h2>
                  Contact Us
                </h2>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-5 offset-md-1">
              <div class="form_container contact-form">
                <form action="">
                  <div>
                    <input type="text" placeholder="Your Name" />
                  </div>
                  <div>
                    <input type="text" placeholder="Phone Number" />
                  </div>
                  <div>
                    <input type="email" placeholder="Email" />
                  </div>
                  <div>
                    <input type="text" class="message-box" placeholder="Message" />
                  </div>
                  <div class="btn_box">
                    <button>
                      SEND
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div class="col-lg-7 col-md-6 px-0">
              <div class="map_container">
                <div class="map">
                  <div id="googleMap">
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- end contact section -->
  
  
  
  
  
  
  <!-- info section -->
  
  <section class="info_section layout_padding2">
    <div class="container">
      <div class="row">
        <div class="col-md-6 col-lg-3 info_col">
          <div class="info_contact">
            <h4>
              Address
            </h4>
            <div class="contact_link_box">
              <a href="https://maps.app.goo.gl/jTQ4DTdp2eTzmTCU8">
                <i class="fa fa-map-marker" aria-hidden="true"></i>
                <span>
                  Location
                </span>
              </a>
              <a routerLink="">
                <i class="fa fa-phone" aria-hidden="true"></i>
                <span>
                  Call : +91 9008160307
                </span>
              </a>
              <a routerLink="">
                <i class="fa fa-envelope" aria-hidden="true"></i>
                <span>
                  Email : info@jenudigitech.com
                </span>
              </a>
            </div>
          </div>
          <div class="info_social">
            <a routerLink="">
              <i class="fa fa-facebook" aria-hidden="true"></i>
            </a>
            <a routerLink="">
              <i class="fa fa-twitter" aria-hidden="true"></i>
            </a>
            <a routerLink="">
              <i class="fa fa-linkedin" aria-hidden="true"></i>
            </a>
            <a routerLink="">
              <i class="fa fa-instagram" aria-hidden="true"></i>
            </a>
          </div>
        </div>
        <!-- <div class="col-md-6 col-lg-3 info_col">
          <div class="info_detail">
            <h4>
              Info
            </h4>
            <p>
              necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful
            </p>
          </div>
        </div> -->
        <div class="col-md-6 col-lg-2 mx-auto info_col">
          <div class="info_link_box">
            <h4>
              Links
            </h4>
            <div class="info_links">
              <a class="" routerLink="/our-solutions">
                <img src="../assets/nav-bullet.png" alt="">
                Home
              </a>
              <a class="" routerLink="/about-us">
                <img src="../assets/nav-bullet.png" alt="">
                About
              </a>
              <a class="" routerLink="/services">
                <img src="../assets/nav-bullet.png" alt="">
                Services
              </a>
              <a class="active" routerLink="/contact-us">
                <img src="../assets/nav-bullet.png" alt="">
                Contact Us
              </a>
            </div>
          </div>
        </div>
        <!-- <div class="col-md-6 col-lg-3 info_col ">
          <h4>
            Subscribe
          </h4>
          <form action="#">
            <input type="text" placeholder="Enter email" />
            <button type="submit">
              Subscribe
            </button>
          </form>
        </div> -->
      </div>
    </div>
  </section>
  
  <!-- end info section -->
  
  <!-- footer section -->
  <section class="footer_section">
    <div class="container">
      <p>
        &copy; <span id="displayYear">{{displayYear}}</span> All Rights Reserved By
        Jenu Digitech Labs Private Limited
      </p>
    </div>
  </section>
  
  