<div class="hero_area">
  <!-- header section strats -->
  <header class="header_section">
    <div class="header_top">
      <div class="container-fluid ">
        <div class="contact_nav">
          <a routerLink="">
            <i class="fa fa-phone" aria-hidden="true"></i>
            <span>
              Call : +91 9008160307
            </span>
          </a>
          <a routerLink="">
            <i class="fa fa-envelope" aria-hidden="true"></i>
            <span>
              Email : info@jenudigitech.com
            </span>
          </a>
          <a href="https://maps.app.goo.gl/jTQ4DTdp2eTzmTCU8">
            <i class="fa fa-map-marker" aria-hidden="true"></i>
            <span>
              Location
            </span>
          </a>
        </div>
      </div>
    </div>
    <div class="header_bottom">
      <div class="container-fluid">
        <nav class="navbar navbar-expand-lg custom_nav-container ">
          <a class="navbar-brand" routerLink="/our-solutions">
            <span>
              <span class="jenu-color">Jenu </span><span class="digitech-color">Digitech Labs Private Limited</span>
            </span>
          </a>

        
          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"  (click)="showNavBar()" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class=""> </span>
          </button>

          <div class="navbar-collapse" id="navbarSupportedContent"   *ngIf="navBar">
            <ul class="navbar-nav  ">
              <li class="nav-item ">
                <a class="nav-link" routerLink="/our-solutions">Home <span class="sr-only">(current)</span></a>
              </li>
              <li class="nav-item active">
                <a class="nav-link" routerLink="/services">Services</a>
              </li>
              <li class="nav-item ">
                <a class="nav-link" routerLink="/about-us"> About Us</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" routerLink="/contact-us">Contact Us</a>
              </li>
              <!-- <li class="nav-item">
                <a class="nav-link" routerLink="#"> <i class="fa fa-user" aria-hidden="true"></i> Login</a>
              </li>
              <form class="form-inline">
                <button class="btn  my-2 my-sm-0 nav_search-btn" type="submit">
                  <i class="fa fa-search" aria-hidden="true"></i>
                </button>
              </form> -->
            </ul>
          </div>
        </nav>
      </div>
    </div>
  </header>


  <section class="service_section layout_padding">
    <div class="service_container">
      <div class="container ">
        <div class="heading_container">
          <h2>
            Our <span>Services</span>
          </h2>
          <p>
            End-to-end project planning, execution, legacy data migration, post go-live hyper care, and support.
          </p>
        </div>
        <div class="row">
          <div class="col-md-6 ">
            <div class="box ">
              <div class="img-box">
                <img src="../assets/implementation.png" alt="">
              </div>
              <div class="detail-box">
                <h5>
                  Implementation
                </h5>
                <p>
                  At JDL we work to an agreed plan that  is realistic, supported by a road map  of miniature project milestones with  our highly skilled delivery team.
                  <br/><br/>
                  Our implementation services covers;  North America, Europe, Asia- Pacific  and the Middle East.
                </p>
                <a routerLink="">
                  Read More
                </a>
              </div>
            </div>
          </div>
          <div class="col-md-6 ">
            <div class="box ">
              <div class="img-box">
                <img src="../assets/upgrades.jpg" alt="">
              </div>
              <div class="detail-box">
                <h5>
                  Upgrade and Migration
                </h5>
                <p>
                  <span style="font-weight: bold;">Impact your business where it matters:</span><br/> Get the most out of SAP’s next  generation of business applications,  analytics software, digital supply
chain and enterprise cloud.<br/><br/>
Making the business case for Selective
Transformation<br/><br/>
Identifying and prioritizing critical areas  and actions
Accelerate and right-size the  transformation journey

                </p>
                <a routerLink="">
                  Read More
                </a>
              </div>
            </div>
          </div>
          <div class="col-md-6 ">
            <div class="box ">
              <div class="img-box">
                <img src="../assets/support.jpg" alt="">
              </div>
              <div class="detail-box">
                <h5>
                  Maintenance
                </h5>
                <p>
                  <span style="font-weight: bold;">Maximize the return on your SAP  investment :</span><br/>
We have access to the state-of-the-art  infrastructure which allows us
to provide all our customers with high  quality support services 24/7.<br/><br/>
Quick Turnaround Time<br/><br/>
We support clients in Asia Pacific,  Europe,Middle East and North America

                </p>
                <a routerLink="">
                  Read More
                </a>
              </div>
            </div>
          </div>
          <div class="col-md-6 ">
            <div class="box ">
              <div class="img-box">
                <img src="../assets/development.png" alt="">
              </div>
              <div class="detail-box">
                <h5>
                  Development
                </h5>
                <p>
                  <span style="font-weight: bold;">Various application developments that we can delivery using the below listed technologies:</span><br/>
                  Interfaces: Cloud platform  Integration (CPI),PI/PO , Barcode/ RFID.<br/>
                  Workflow: Automation  Approvals.<br/>
                  Data Migration: Idoc, LSMW, LTMC.<br/>
                  Cloud BTP: API, CAP,RAP.<br/>
                  ABAP on HANA: ODATA , UI5OOPS, BOPF.<br/>

                </p>
                <a routerLink="">
                  Read More
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
<!-- end about section -->






<!-- info section -->

<section class="info_section layout_padding2">
  <div class="container">
    <div class="row">
      <div class="col-md-6 col-lg-3 info_col">
        <div class="info_contact">
          <h4>
            Address
          </h4>
          <div class="contact_link_box">
            <a href="https://maps.app.goo.gl/jTQ4DTdp2eTzmTCU8">
              <i class="fa fa-map-marker" aria-hidden="true"></i>
              <span>
                Location
              </span>
            </a>
            <a routerLink="">
              <i class="fa fa-phone" aria-hidden="true"></i>
              <span>
                Call : +91 9008160307
              </span>
            </a>
            <a routerLink="">
              <i class="fa fa-envelope" aria-hidden="true"></i>
              <span>
                Email : info@jenudigitech.com
              </span>
            </a>
          </div>
        </div>
        <div class="info_social">
          <a routerLink="">
            <i class="fa fa-facebook" aria-hidden="true"></i>
          </a>
          <a routerLink="">
            <i class="fa fa-twitter" aria-hidden="true"></i>
          </a>
          <a routerLink="">
            <i class="fa fa-linkedin" aria-hidden="true"></i>
          </a>
          <a routerLink="">
            <i class="fa fa-instagram" aria-hidden="true"></i>
          </a>
        </div>
      </div>
      <!-- <div class="col-md-6 col-lg-3 info_col">
        <div class="info_detail">
          <h4>
            Info
          </h4>
          <p>
            necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful
          </p>
        </div>
      </div> -->
      <div class="col-md-6 col-lg-2 mx-auto info_col">
        <div class="info_link_box">
          <h4>
            Links
          </h4>
          <div class="info_links">
            <a class="" routerLink="/our-solutions">
              <img src="../assets/nav-bullet.png" alt="">
              Home
            </a>
            <a class="" routerLink="/about-us">
              <img src="../assets/nav-bullet.png" alt="">
              About
            </a>
            <a class="active" routerLink="/services">
              <img src="../assets/nav-bullet.png" alt="">
              Services
            </a>
            <a class="" routerLink="/contact-us">
              <img src="../assets/nav-bullet.png" alt="">
              Contact Us
            </a>
          </div>
        </div>
      </div>
      <!-- <div class="col-md-6 col-lg-3 info_col ">
        <h4>
          Subscribe
        </h4>
        <form action="#">
          <input type="text" placeholder="Enter email" />
          <button type="submit">
            Subscribe
          </button>
        </form>
      </div> -->
    </div>
  </div>
</section>

<!-- end info section -->

<!-- footer section -->
<section class="footer_section">
  <div class="container">
    <p>
      &copy; <span id="displayYear">{{displayYear}}</span> All Rights Reserved By
      Jenu Digitech Labs Private Limited
    </p>
  </div>
</section>

