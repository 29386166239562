import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { JenuDashboardComponent } from './jenu-dashboard/jenu-dashboard.component';
import { JenuServiceComponent } from './jenu-services/jenu-service.component';
import { ContactComponent } from './contact/contact.component';
import { AboutComponent } from './about/about.component';

const routes: Routes = [ 
{ path: 'our-solutions', component: JenuDashboardComponent },
{ path: 'our-solutions/supply-chain-management', component: JenuServiceComponent },
{ path: 'services', component: JenuServiceComponent },
{ path: 'contact-us', component: ContactComponent },
{ path: 'about-us', component: AboutComponent },
{path: '', redirectTo: 'our-solutions', pathMatch: 'full'},
{path: '**', redirectTo: 'our-solutions'}
];



@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
