import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { JenuDashboardComponent } from './jenu-dashboard/jenu-dashboard.component';
import { JenuServiceComponent } from './jenu-services/jenu-service.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import * as $ from "jquery";
@NgModule({
  declarations: [
    AppComponent,
    JenuDashboardComponent,
    JenuServiceComponent,
    ContactComponent,
    AboutComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
