import { Component, HostListener } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'jenu-dashboard',
  templateUrl: './jenu-dashboard.component.html',
  styleUrls: ['./jenu-dashboard.component.scss']
})
export class JenuDashboardComponent {

  carItem1 = true;
  carItem2 = false;
carItem3 = false;
displayYear: number = new Date().getFullYear();
navBar= true;
showNavBar(){
  this.navBar  = !this.navBar;
}

@HostListener('window:resize', ['$event'])
onResize() {
if(window.innerWidth  >= 992){
  this.navBar = true;
}
}
showCarItem(value:number){
if(value == 1){
  this.carItem1 = true;
  this.carItem2= false;
  this.carItem3= false;
}

else if(value == 2){
  this.carItem1 = false;
  this.carItem2= true;
  this.carItem3= false;
}

else if(value == 3){
  this.carItem1 = false;
  this.carItem2= false;
  this.carItem3= true;
}
}
}
