import { Component, HostListener } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent {
  displayYear: number = new Date().getFullYear();
  navBar= true;
  showNavBar(){
    this.navBar  = !this.navBar;
  }

  @HostListener('window:resize', ['$event'])
onResize() {
  if(window.innerWidth  >= 992){
    this.navBar = true;
  };
}
}
