import { Component, HostListener } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent {

displayYear: number = new Date().getFullYear();
navBar= true;
showNavBar(){
  this.navBar  = !this.navBar;
}

@HostListener('window:resize', ['$event'])
onResize() {
if(window.innerWidth  >= 992){
  this.navBar = true;
};
}
}
