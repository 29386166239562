<div class="hero_area">
    <!-- header section strats -->
    <header class="header_section">
      <div class="header_top">
        <div class="container-fluid ">
          <div class="contact_nav">
            <a routerLink="">
              <i class="fa fa-phone" aria-hidden="true"></i>
              <span>
                Call : +91 9008160307
              </span>
            </a>
            <a routerLink="">
              <i class="fa fa-envelope" aria-hidden="true"></i>
              <span>
                Email : info@jenudigitech.com
              </span>
            </a>
            <a href="https://maps.app.goo.gl/jTQ4DTdp2eTzmTCU8">
              <i class="fa fa-map-marker" aria-hidden="true"></i>
              <span>
                Location
              </span>
            </a>
          </div>
        </div>
      </div>
      <div class="header_bottom">
        <div class="container-fluid">
          <nav class="navbar navbar-expand-lg custom_nav-container ">
            <a class="navbar-brand" routerLink="/our-solutions">
              <span>
                <span class="jenu-color">Jenu </span><span class="digitech-color">Digitech Labs Private Limited</span>
              </span>
            </a>

    
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"  (click)="showNavBar()" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span class=""> </span>
            </button>
  
            <div class="navbar-collapse" id="navbarSupportedContent"   *ngIf="navBar">
              <ul class="navbar-nav  ">
                <li class="nav-item active">
                  <a class="nav-link" routerLink="/our-solutions">Home <span class="sr-only">(current)</span></a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" routerLink="/services">Services</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" routerLink="/about-us"> About Us</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" routerLink="/contact-us">Contact Us</a>
                </li>
                <!-- <li class="nav-item">
                  <a class="nav-link" routerLink="#"> <i class="fa fa-user" aria-hidden="true"></i> Login</a>
                </li>
                <form class="form-inline">
                  <button class="btn  my-2 my-sm-0 nav_search-btn" type="submit">
                    <i class="fa fa-search" aria-hidden="true"></i>
                  </button>
                </form> -->
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </header>
    <!-- end header section -->
    <!-- slider section -->
    <section class="slider_section ">
      
      <div id="customCarousel1" class="carousel slide" data-ride="carousel">
        <div class="slider_bg_box">
          <img src="../assets/supplier_chain_management.jfif" alt="">
        </div>
        <div class="carousel-inner">
          <div class="carousel-item active"  *ngIf="carItem1">
            <div class="container ">
              <div class="row">
                <div class="col-md-7 ">
                  <div class="detail-box">
                    <h1>
                      Who we are <br>
                    </h1>
                    <p>
                      * Experts team  of SAP SCM leaders
                      and SAP Technical consultants.
                      <br/>
                      * Blended expertise across industry  segments with top-notch  consulting capabilities.
                      <br/>
                      * Domain experience in
                      CPG,Retail,Chemicals, Fertilizers,  Automotive,and Electronics  Pharma, Oil & Gas.
                    </p>
                    <!-- <div class="btn-box">
                      <a routerLink="" class="btn1">
                        Get A Quote
                      </a>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="carousel-item  active"  *ngIf="carItem2">
            <div class="container ">
              <div class="row">
                <div class="col-md-7 ">
                  <div class="detail-box">
                    <h1>
                      What we do <br>
                    </h1>
                    <p>
                    * Digital Transformation in SAP Supply Chain Consulting.<br/>
                    * Staff Augmentation.<br/>
                    * Build Niche skills.<br/>
                    * Reseller -Partner Products.
                    </p>
                    <!-- <div class="btn-box">
                      <a routerLink="" class="btn1">
                        Get A Quote
                      </a>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="carousel-item active"  *ngIf="carItem3">
            <div class="container ">
              <div class="row">
                <div class="col-md-7 ">
                  <div class="detail-box">
                    <h1>
                      Our Key Focus<br>
                    </h1>
                    <p>
                      * Bolt-on Product Development.<br/>
                      * Programs  to cover all  aspects of  Etiquette and  Behavior, Personality  Development, Effective  Communication Skills.<br/>
                      * Partner products.<br/>
                      * Corporate training.
                      </p>
                    <!-- <div class="btn-box">
                      <a routerLink="" class="btn1">
                        Get A Quote
                      </a>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ol class="carousel-indicators">
          <li  [ngClass]="{'active' : carItem1}" (click) ="showCarItem(1)"></li>
          <li   [ngClass]="{'active' : carItem2}" (click) ="showCarItem(2)"></li>
          <li   [ngClass]="{'active' : carItem3}" (click) ="showCarItem(3)"></li>
        </ol>
      </div>

    </section>
    <!-- end slider section -->
  </div>


  <!-- service section -->

  <section class="service_section layout_padding">
    <div class="service_container">
      <div class="container ">
        <div class="heading_container">
          <h2>
            Our <span>Services</span>
          </h2>
          <p>
            End-to-end project planning, execution, legacy data migration, post go-live hyper care, and support.
          </p>
        </div>
        <div class="row">
          <div class="col-md-6 ">
            <div class="box ">
              <div class="img-box">
                <img src="../assets/implementation.png" alt="">
              </div>
              <div class="detail-box">
                <h5>
                  Implementation
                </h5>
                <p>
                  At JDL we work to an agreed plan that  is realistic, supported by a road map  of miniature project milestones with  our highly skilled delivery team.
                  <br/><br/>
                  Our implementation services covers;  North America, Europe, Asia- Pacific  and the Middle East.
                </p>
                <a routerLink="">
                  Read More
                </a>
              </div>
            </div>
          </div>
          <div class="col-md-6 ">
            <div class="box ">
              <div class="img-box">
                <img src="../assets/upgrades.jpg" alt="">
              </div>
              <div class="detail-box">
                <h5>
                  Upgrade and Migration
                </h5>
                <p>
                  <span style="font-weight: bold;">Impact your business where it matters:</span><br/> Get the most out of SAP’s next  generation of business applications,  analytics software, digital supply
chain and enterprise cloud.<br/><br/>
Making the business case for Selective
Transformation<br/><br/>
Identifying and prioritizing critical areas  and actions
Accelerate and right-size the  transformation journey

                </p>
                <a routerLink="">
                  Read More
                </a>
              </div>
            </div>
          </div>
          <div class="col-md-6 ">
            <div class="box ">
              <div class="img-box">
                <img src="../assets/support.jpg" alt="">
              </div>
              <div class="detail-box">
                <h5>
                  Maintenance
                </h5>
                <p>
                  <span style="font-weight: bold;">Maximize the return on your SAP  investment :</span><br/>
We have access to the state-of-the-art  infrastructure which allows us
to provide all our customers with high  quality support services 24/7.<br/><br/>
Quick Turnaround Time<br/><br/>
We support clients in Asia Pacific,  Europe,Middle East and North America

                </p>
                <a routerLink="">
                  Read More
                </a>
              </div>
            </div>
          </div>
          <div class="col-md-6 ">
            <div class="box ">
              <div class="img-box">
                <img src="../assets/development.png" alt="">
              </div>
              <div class="detail-box">
                <h5>
                  Development
                </h5>
                <p>
                  <span style="font-weight: bold;">Various application developments that we can delivery using the below listed technologies:</span><br/>
                  Interfaces: Cloud platform  Integration (CPI),PI/PO , Barcode/ RFID.<br/>
                  Workflow: Automation  Approvals.<br/>
                  Data Migration: Idoc, LSMW, LTMC.<br/>
                  Cloud BTP: API, CAP,RAP.<br/>
                  ABAP on HANA: ODATA , UI5OOPS, BOPF.<br/>

                </p>
                <a routerLink="">
                  Read More
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- end service section -->


  <!-- about section -->

  <section class="about_section layout_padding-bottom">
    <div class="container  ">
      <div class="row">
        <div class="col-md-6">
          <div class="detail-box">
            <div class="heading_container">
              <h2>
                About <span>Us</span>
              </h2>
            </div>
            <p>
              
              <span style="font-weight: bold;">Values:</span><br/>Honesty, Integrity, Transparency and  Passion defines our Value System.<br/>
              <span style="font-weight: bold;">Vision:</span><br/>To be an outstanding collaborator to our  clients  through quality and dedicated  Service all the time.<br/>           
              <span style="font-weight: bold;">Mission:</span><br/>To excel in performance through  continuous improvement in quality  standards and application of latest  methodologies and practices.
            </p>
            <a routerLink="">
              Read More
            </a>
          </div>
        </div>
        <div class="col-md-6 ">
          <div class="img-box">
            <img src="../assets/aboutus.png" alt="">
          </div>
        </div>

      </div>
    </div>
  </section>

  <!-- end about section -->

  <!-- track section -->

  <section class="track_section layout_padding">
    <div class="track_bg_box">
      <img class= "supply-chain"src="../assets/supply_chain.png" alt="">
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <div class="heading_container">
            <h2>
              SAP Supply Chain and Data Services Capabilities
            </h2>
          </div>
          <p>
            Supply Chain Planning  APO/IBP.<br/>
            Supply Chain Execution EWM/TM/Yard Logistics.<br/>
            Manufacturing Integration & Operations MII/MES.<br/>
            SAP Data Services.<br/>
            Incalbo-Partner product.   
          </p>
          <!-- <form action="">
            <input type="text" placeholder="Enter Your Tracking Number" />
            <button type="submit">
              Track
            </button>
          </form> -->
        </div>
      </div>
    </div>
  </section>

  <!-- end track section -->

  <!-- client section -->

  <section class="client_section layout_padding">
    <div class="container">
      <div class="heading_container">
        <h2>
           Our <span>Leadership</span>
        </h2>
      </div>
      <div class="client_container">
        <div class="carousel-wrap ">
            <div class="item">
              <div class="box">
                <div class="detail-box">
                  <p>
                    26+ years of Experience in consulting & enterprise application services in Supply Chain Management & Logistics  domain.<br/>
                    Proven track record of building Supply Chain applications business, competency development, Alliance  management and go to market with P&L responsibility.<br/>
                    Proven track record of delivering on-demand enterprise solutions.<br/>
                    Proven capabilities to manage complex multi-dimensional engagements delivering complex solutions.<br/>
                    Strong understanding of Hi-Tech, Manufacturing, Healthcare 3PL, Oil, Pharma, Chemicals and Retail Domains.<br/>
                    Experience in management of fortune 100 Client relationship across geographies.<br/>
                    Experience in setting up the Product implementation and Product support organizations.<br/>
                    </p>
                </div>
                <div class="client_id">
                  <div class="img-box">
                    <img src="../assets/putraj.jpg" alt="" class="img-1">
                  </div>
                  <div class="name">
                    <h6>
                      Puttaraju Hanu (Raj)
                    </h6>
                    <p>
                      Founder & CEO
                    </p>
                  </div>
                </div>
              </div>

           
           

          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- end client section -->

  <!-- contact section -->
  <section class="contact_section">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-4 col-md-5 offset-md-1">
          <div class="heading_container">
            <h2>
              Contact Us
            </h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 col-md-5 offset-md-1">
          <div class="form_container contact-form">
            <form action="">
              <div>
                <input type="text" placeholder="Your Name" />
              </div>
              <div>
                <input type="text" placeholder="Phone Number" />
              </div>
              <div>
                <input type="email" placeholder="Email" />
              </div>
              <div>
                <input type="text" class="message-box" placeholder="Message" />
              </div>
              <div class="btn_box">
                <button>
                  SEND
                </button>
              </div>
            </form>
          </div>
        </div>
        <div class="col-lg-7 col-md-6 px-0">
          <div class="map_container">
            <div class="map">
              <div id="googleMap">
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- end contact section -->

  <!-- info section -->

  <section class="info_section layout_padding2">
    <div class="container">
      <div class="row">
        <div class="col-md-6 col-lg-3 info_col">
          <div class="info_contact">
            <h4>
              Address
            </h4>
            <div class="contact_link_box">
              <a href="https://maps.app.goo.gl/jTQ4DTdp2eTzmTCU8">
                <i class="fa fa-map-marker" aria-hidden="true"></i>
                <span>
                  Location
                </span>
              </a>
              <a routerLink="">
                <i class="fa fa-phone" aria-hidden="true"></i>
                <span>
                  Call : +91 9008160307
                </span>
              </a>
              <a routerLink="">
                <i class="fa fa-envelope" aria-hidden="true"></i>
                <span>
                  Email : info@jenudigitech.com
                </span>
              </a>
            </div>
          </div>
          <div class="info_social">
            <a routerLink="">
              <i class="fa fa-facebook" aria-hidden="true"></i>
            </a>
            <a routerLink="">
              <i class="fa fa-twitter" aria-hidden="true"></i>
            </a>
            <a routerLink="">
              <i class="fa fa-linkedin" aria-hidden="true"></i>
            </a>
            <a routerLink="">
              <i class="fa fa-instagram" aria-hidden="true"></i>
            </a>
          </div>
        </div>
        <!-- <div class="col-md-6 col-lg-3 info_col">
          <div class="info_detail">
            <h4>
              Info
            </h4>
            <p>
              necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful
            </p>
          </div>
        </div> -->
        <div class="col-md-6 col-lg-2 mx-auto info_col">
          <div class="info_link_box">
            <h4>
              Links
            </h4>
            <div class="info_links">
              <a class="active" routerLink="/our-solutions">
                <img src="../assets/nav-bullet.png" alt="">
                Home
              </a>
              <a class="" routerLink="/about-us">
                <img src="../assets/nav-bullet.png" alt="">
                About
              </a>
              <a class="" routerLink="/services">
                <img src="../assets/nav-bullet.png" alt="">
                Services
              </a>
              <a class="" routerLink="/contact-us">
                <img src="../assets/nav-bullet.png" alt="">
                Contact Us
              </a>
            </div>
          </div>
        </div>
        <!-- <div class="col-md-6 col-lg-3 info_col ">
          <h4>
            Subscribe
          </h4>
          <form action="#">
            <input type="text" placeholder="Enter email" />
            <button type="submit">
              Subscribe
            </button>
          </form>
        </div> -->
      </div>
    </div>
  </section>

  <!-- end info section -->

  <!-- footer section -->
  <section class="footer_section">
    <div class="container">
      <p>
        &copy; <span id="displayYear">{{displayYear}}</span> All Rights Reserved By
        Jenu Digitech Labs Private Limited
      </p>
    </div>
  </section>

  